import Swiper, {Navigation, Pagination, Autoplay, Parallax} from "swiper";
import "swiper/css";
import "swiper/css/navigation";

import "./styles/style.scss";

$(function () {
	const headerSwiper = new Swiper(".header_swiper", {
		modules      : [Navigation],
		navigation   : {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		loop         : true,
		slidesPerView: 1,
		autoplay     : {
			delay: 5000,
		},
		speed        : 750,
	});

	const indexSwiper = new Swiper(".index_swiper", {
		modules       : [Navigation, Pagination, Autoplay],
		navigation    : {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		loop          : true,
		slidesPerView : 1,
		autoplay      : {
			delay            : 4500,
			pauseOnMouseEnter: true,
		},
		speed         : 750,
		centeredSlides: true,
		spaceBetween  : 50,
		pagination    : {
			el       : ".swiper-pagination",
			type     : "bullets",
			clickable: true,
		},
	});


	const offresSwiper = new Swiper(".offres_swiper", {
		modules      : [Navigation, Parallax],
		navigation   : {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		loop         : true,
		slidesPerView: 1,
		speed        : 750,
		parallax: true,
		breakpoints  : {
			0 : {
				parallax: false,
			},
			768: {
				parallax: true,
			},
		},
	});

});
